import React, { useState } from 'react';
import {
    Link,
    useRecordContext,
    useTranslate,
    useNotify,
    useRefresh,
} from 'react-admin';
import { IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
interface Props {
    showRemove?: boolean;
    label?: string;
    size?: 'small' | 'large';
}

const SourceNameField = ({ showRemove = false, label = '', size }: Props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const [saving, setSaving] = useState(false);
    const refresh = useRefresh();

    const record = useRecordContext();
    if (!record) return null;
    return (
        <Stack
            direction="row"
            alignItems={'baseline'}
            spacing={2}
            sx={{ fontSize: size === 'small' ? '0.7rem' : '1rem' }}
        >
            {record.services.map(service => {
                switch (service) {
                    case 'crm':
                        return (
                            <Link
                                to={`https://oppimisencrm.crm4.dynamics.com/main.aspx?appid=b68a0d15-8769-4bc0-8999-f949f7404bbb&pagetype=entityrecord&etn=contact&id=${record.crm_guid}`}
                                target="_blank"
                                key={`${record.guid}_${service}`}
                                onClick={e => e.stopPropagation()}
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                <span
                                    style={{
                                        color: '#fff',
                                        padding: '1px 6px',
                                        borderRadius: 3,
                                        background: 'rgb(96, 125, 139, .5)',
                                        transition: 'box-shadow 0.3s',
                                    }}
                                    onMouseEnter={e =>
                                        (e.currentTarget.style.boxShadow =
                                            '0 3px 6px rgba(0, 0, 0, 0.2)')
                                    }
                                    onMouseLeave={e =>
                                        (e.currentTarget.style.boxShadow =
                                            'none')
                                    }
                                >
                                    {service.toUpperCase()}
                                </span>
                            </Link>
                        );
                    case 'mpass':
                        return (
                            <div>
                                <span
                                    key={service}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#fff',
                                        padding: '1px 6px',
                                        borderRadius: 3,
                                        background: 'rgb(96, 125, 139, .5)',
                                    }}
                                >
                                    {service.toUpperCase()}
                                </span>
                                {showRemove && (
                                    <IconButton
                                        title={translate(
                                            'resources.user.actions.remove_mpass'
                                        )}
                                        style={{ overflow: 'inherit' }}
                                        disabled={saving}
                                        onClick={() => {
                                            let action = 'REMOVE_MPASS';
                                            let data = {
                                                ...record,
                                                ...{ action },
                                            };
                                            setSaving(true);
                                            fetch(
                                                `${process.env.REACT_APP_INFRA_API}/teacher/${record.guid}`,
                                                {
                                                    method: 'PUT',
                                                    headers: {
                                                        Accept: 'application/json',
                                                        'Content-Type':
                                                            'application/json',
                                                        school: localStorage.getItem(
                                                            'school'
                                                        ),
                                                        authorization:
                                                            'Bearer ' +
                                                            localStorage.getItem(
                                                                'token'
                                                            ),
                                                    },
                                                    body: JSON.stringify(data),
                                                }
                                            ).then(ret => {
                                                setSaving(false);
                                                if (ret.status !== 200) {
                                                    notify(
                                                        translate(
                                                            'notifications.ACTION_ERROR'
                                                        ),
                                                        { type: 'error' }
                                                    );
                                                } else {
                                                    /*All good*/
                                                    setSaving(false);
                                                    notify(
                                                        translate(
                                                            'notifications.MPASS_REMOVED'
                                                        ),
                                                        { type: 'success' }
                                                    );
                                                    refresh();
                                                }
                                            });
                                        }}
                                    >
                                        <DeleteIcon
                                            style={{ color: red[500] }}
                                        />
                                    </IconButton>
                                )}
                            </div>
                        );

                    case 'opepalvelu':
                    case 'finnlectura':
                    case 'opiskelija':
                    case 'edustore':
                    case 'fl_opepalvelu':
                    case 'fl_opiskelija':
                        return (
                            <div>
                                <span
                                    key={service}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#fff',
                                        padding: '1px 6px',
                                        borderRadius: 3,
                                        background: 'rgb(96, 125, 139, .5)',
                                    }}
                                >
                                    {service
                                        .replace('opepalvelu', 'oop')
                                        .replace('fl_opepalvelu', 'fl')
                                        .replace('fl_opiskelija', 'fl')
                                        .replace('opiskelija', 'kv')
                                        .toUpperCase()}
                                </span>
                            </div>
                        );
                    default:
                        return null;
                }
            })}
        </Stack>
    );
};

export default SourceNameField;
