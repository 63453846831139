import * as React from 'react';
import {
    CreateButton,
    DateField,
    FilterButton,
    List,
    NumberField,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
    useNotify,
    WithListContext,
    DatagridLoading,
    Link,
} from 'react-admin';
import { useMediaQuery, Theme, Typography } from '@mui/material';
import { Alert } from '@mui/material';

import CustomStatusField from '../../controls/CustomStatusField';
import PupilListAside from './PupilListAside';
import get from 'lodash/get';
import CustomPagination from '../../common/CustomPagination';
import SourceNameField from '../SourceNameField';
import SessionBadge from '../controls/SessionBadge';
// import ProductFullNameField from '../product/ProductFullNameField';
// import SubscriberFullNameField from './SubscriberFullNameField';

const pupilFilters = [<SearchInput source="q" alwaysOn />];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}
            <SelectColumnsButton />
            {/* <CreateButton /> */}
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

const PupilList = () => {
    const notify = useNotify();
    const handleOnClick = e => {
        e.stopPropagation();
        navigator.clipboard.writeText(e.target.innerText);
        //notify(e.target.innerText + ' kopioitu leikepöydälle.', { type: 'info' });
        notify(
            <Alert severity="info">
                {e.target.innerText} kopioitu leikepöydälle.
            </Alert>
        );
    };

    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = [
        'public.firstname',
        'public.lastname',
        'public.code',
        'last_login',
        'registered',
        'services',
    ];

    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? pupilFilters : undefined}
            sort={{ field: 'registered', order: 'DESC' }}
            filter={{ need_count: false, sessions: true, licenses: false }}
            perPage={25}
            aside={<PupilListAside />}
            actions={<ListActions />}
            pagination={true ? <CustomPagination /> : undefined}
        >
            <WithListContext
                render={({ isLoading, isFetching, data }) =>
                    isLoading || isFetching ? (
                        <DatagridLoading nbChildren={6} nbFakeLines={8} />
                    ) : (
                        <DatagridConfigurable
                            //optimized
                            omit={['guid']}
                            rowClick="edit"
                            //rowClick={(id: any, basePath: any, record: any) => {window.open("https://oppimateriaalit-aspa.otava.fi/#/"+basePath+"/"+id, "_blank");return false;}}
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                        >
                            {/* {columns} */}
                            <TextField
                                source="guid"
                                key="guid"
                                sortable={sortable.includes('guid')}
                            />
                            <TextField
                                source="public.firstname"
                                key="firstname"
                                sortable={sortable.includes('public.firstname')}
                                onClick={handleOnClick}
                            />
                            <TextField
                                source="public.lastname"
                                key="lastname"
                                sortable={sortable.includes('public.lastname')}
                                onClick={handleOnClick}
                            />
                            <TextField
                                source="public.code"
                                key="code"
                                sortable={sortable.includes('public.code')}
                                onClick={handleOnClick}
                            />
                            <FunctionField
                                source="school.title"
                                key="school"
                                render={record =>
                                    record.schools.map(school => (
                                        <Link
                                            key={school.guid}
                                            style={{
                                                textDecoration: 'none',
                                                color: '#000',
                                            }}
                                            className="inner_link"
                                            to={'/school/' + school.guid}
                                            onClick={e => e.stopPropagation()}
                                        >
                                            <Typography
                                                variant="body2"
                                                display="flex"
                                                flexWrap="nowrap"
                                                alignItems="center"
                                                component="div"
                                            >
                                                {school.name}
                                            </Typography>
                                        </Link>
                                    ))
                                }
                                sortable={sortable.includes('school')}
                            />
                            <SessionBadge
                                source="last_login"
                                options={{
                                    showTTL: false,
                                }}
                            >
                                <DateField
                                    source="last_login"
                                    key="last_login"
                                    showTime={true}
                                    locales="fi-FI"
                                    sortable={sortable.includes('last_login')}
                                />
                            </SessionBadge>
                            <DateField
                                source="registered"
                                key="registered"
                                showTime={false}
                                locales="fi-FI"
                                sortable={sortable.includes('registered')}
                            />
                            <SourceNameField label="Lähde" size="small" />
                        </DatagridConfigurable>
                    )
                }
            />
        </List>
    );
};

export default PupilList;
