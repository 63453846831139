import React, { useEffect, useState, createElement, useMemo } from 'react';
import {
    useEditContext,
    useDataProvider,
    useTranslate,
    useNotify,
    useResourceContext,
    DateField,
    RecordContextProvider,
    useRefresh,
    Labeled,
} from 'react-admin';
import {
    Grid,
    Box,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    Alert,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Avatar,
    ListItemAvatar,
    Stack,
    Chip,
    CircularProgress,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { MergeUser } from './type';
import { debounce } from 'lodash';

import { appIcons } from '../../../components/statistics/common';
import user from '..';
import { text } from 'stream/consumers';
import SessionBadge from './SessionBadge';

const UserDetails = ({ userData }) => {
    const resource = useResourceContext();
    const translate = useTranslate();
    if (!userData) return null;
    if (!userData.last_login) userData.last_login = userData.author.created;
    return (
        <RecordContextProvider value={userData}>
            <Stack direction="column" spacing={1}>
                <Grid container spacing={1}>
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <SessionBadge
                                    source=""
                                    options={{
                                        showTTL: false,
                                        showSessionDelete: false,
                                    }}
                                >
                                    {createElement(appIcons[resource])}
                                </SessionBadge>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <>
                                        <Typography variant="body1">
                                            {userData?.public?.firstname}{' '}
                                            {userData?.public?.lastname}{' '}
                                            {userData?.services?.find(
                                                s => s === 'mpass'
                                            ) && (
                                                <span
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: '#fff',
                                                        fontSize: 12,
                                                        padding: '1px 6px',
                                                        borderRadius: 3,
                                                        marginRight: 3,
                                                        height: 15,
                                                        marginLeft: 3,
                                                        background:
                                                            'rgb(96, 125, 139, .5)',
                                                    }}
                                                >
                                                    mpass
                                                </span>
                                            )}
                                            <Typography variant="body2">
                                                {userData.public.email ||
                                                    userData.public.code ||
                                                    userData.username}
                                            </Typography>
                                            {userData.public?.emails?.map(
                                                email => (
                                                    <Typography
                                                        variant="body2"
                                                        sx={{ opacity: 0.8 }}
                                                    >
                                                        {email}
                                                    </Typography>
                                                )
                                            )}
                                            {userData.public?.[
                                                'infra:merged'
                                            ]?.map(email => (
                                                <Typography
                                                    variant="body2"
                                                    sx={{ opacity: 0.8 }}
                                                >
                                                    yhdistetty: {email}
                                                </Typography>
                                            ))}
                                            <Typography variant="body2">
                                                <Box
                                                    sx={{
                                                        color:
                                                            userData.status > 0
                                                                ? 'error.main'
                                                                : 'success.main',
                                                    }}
                                                >
                                                    {translate(
                                                        `resources.user.status.${userData.status}`
                                                    )}
                                                </Box>
                                            </Typography>
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            marginTop={1}
                                            sx={{
                                                width: '100%',
                                            }}
                                        >
                                            <Typography
                                                component="span"
                                                variant="body2"
                                            >
                                                Rekisteröitynyt
                                            </Typography>
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    height: '1px',
                                                    backgroundImage:
                                                        'linear-gradient(to right, transparent, black 50%, transparent)',
                                                    backgroundSize: '4px 1px',
                                                    margin: '0 8px',
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    opacity: 1,
                                                    marginLeft: 'auto', // Float right
                                                }}
                                            >
                                                {new Date(
                                                    userData.author.created
                                                ).toLocaleString('fi-FI', {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: 'numeric',
                                                })}
                                            </Box>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{
                                                width: '100%',
                                            }}
                                        >
                                            <Typography
                                                component="span"
                                                variant="body2"
                                            >
                                                Kirjautunut
                                            </Typography>
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    height: '1px',
                                                    backgroundImage:
                                                        'linear-gradient(to right, transparent, black 50%, transparent)',
                                                    backgroundSize: '4px 1px',
                                                    margin: '0 8px',
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    opacity: 1,
                                                    marginLeft: 'auto', // Float right
                                                }}
                                            >
                                                {new Date(
                                                    userData.last_login
                                                ).toLocaleString('fi-FI', {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: 'numeric',
                                                })}
                                            </Box>
                                        </Stack>
                                    </>
                                }
                                secondary={
                                    <React.Fragment>
                                        {userData?.licenses?.filter(
                                            l =>
                                                l.subscriber_guid ===
                                                userData.guid
                                        )?.length > 0 && (
                                            <>
                                                <Divider
                                                    component="li"
                                                    sx={{
                                                        margin: 1,
                                                        opacity: 0.6,
                                                    }}
                                                />
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        sx={{
                                                            color: 'text.primary',
                                                            display: 'inline',
                                                        }}
                                                    >
                                                        Omat voimassaolevat
                                                        lisenssit:
                                                    </Typography>
                                                    {userData?.licenses
                                                        ?.filter(
                                                            l =>
                                                                l.subscriber_guid ===
                                                                userData.guid
                                                        )
                                                        ?.map((license, i) => (
                                                            <Stack
                                                                key={`${userData.guid}-${license.guid}-${i}`}
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="space-between"
                                                                sx={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                >
                                                                    {
                                                                        license.name
                                                                    }
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                        height: '1px',
                                                                        backgroundImage:
                                                                            'linear-gradient(to right, transparent, black 50%, transparent)',
                                                                        backgroundSize:
                                                                            '4px 1px',
                                                                        margin: '0 8px',
                                                                    }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        opacity: 1,
                                                                        marginLeft:
                                                                            'auto', // Float right
                                                                    }}
                                                                >
                                                                    {new Date(
                                                                        license.created
                                                                    ).toLocaleDateString(
                                                                        'fi-FI'
                                                                    )}
                                                                </Box>
                                                            </Stack>
                                                        ))}
                                                </React.Fragment>
                                            </>
                                        )}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                        }}
                    >
                        {userData.schools
                            .filter(
                                school =>
                                    school.status === 0 &&
                                    school.school_status === 0
                            )
                            .map(school => (
                                <ListItem
                                    key={`${userData.guid}-${school.guid}`}
                                    alignItems="flex-start"
                                >
                                    <ListItemAvatar>
                                        {createElement(appIcons.school)}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{
                                                    width: '100%',
                                                    marginBottom: 1,
                                                }}
                                            >
                                                <Typography
                                                    component="span"
                                                    variant="body1"
                                                >
                                                    {school.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                        height: '1px',
                                                        backgroundImage:
                                                            'linear-gradient(to right, transparent, black 50%, transparent)',
                                                        backgroundSize:
                                                            '4px 1px',
                                                        margin: '0 8px',
                                                        opacity: 0,
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        opacity: 1,
                                                        marginLeft: 'auto', // Float right
                                                    }}
                                                >
                                                    {new Date(
                                                        school.joined
                                                    ).toLocaleDateString(
                                                        'fi-FI'
                                                    )}
                                                </Box>
                                            </Stack>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <React.Fragment>
                                                    {userData?.classes
                                                        ?.filter(
                                                            g =>
                                                                g.status ===
                                                                    0 &&
                                                                g.class_status ===
                                                                    0 &&
                                                                g.school_guid ===
                                                                    school.guid
                                                        )
                                                        ?.map((group, i) => (
                                                            <React.Fragment>
                                                                {i === 0 && (
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        sx={{
                                                                            color: 'text.primary',
                                                                            display:
                                                                                'inline',
                                                                        }}
                                                                    >
                                                                        Ryhmät:
                                                                    </Typography>
                                                                )}

                                                                <Stack
                                                                    key={`${userData.guid}-${group.guid}-${i}`}
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                    sx={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                    >
                                                                        {
                                                                            group.name
                                                                        }{' '}
                                                                        {group.archived && (
                                                                            <Box
                                                                                sx={{
                                                                                    display:
                                                                                        'inline',
                                                                                    color: 'error.main',
                                                                                    fontSize:
                                                                                        '0.75rem', // Adjust font size if needed
                                                                                }}
                                                                            >
                                                                                Arkistoitu
                                                                            </Box>
                                                                        )}
                                                                    </Typography>
                                                                    <Box
                                                                        sx={{
                                                                            flexGrow: 1,
                                                                            height: '1px',
                                                                            backgroundImage:
                                                                                'linear-gradient(to right, transparent, black 50%, transparent)',
                                                                            backgroundSize:
                                                                                '4px 1px',
                                                                            margin: '0 8px',
                                                                        }}
                                                                    />
                                                                    <Box
                                                                        sx={{
                                                                            opacity: 1,
                                                                            marginLeft:
                                                                                'auto', // Float right
                                                                        }}
                                                                    >
                                                                        {new Date(
                                                                            group.joined
                                                                        ).toLocaleDateString(
                                                                            'fi-FI'
                                                                        )}
                                                                    </Box>
                                                                </Stack>
                                                            </React.Fragment>
                                                        ))}
                                                </React.Fragment>
                                                {userData?.licenses?.filter(
                                                    l =>
                                                        l.subscriber_guid ===
                                                        school?.parent_guid
                                                )?.length > 0 && (
                                                    <>
                                                        <Divider
                                                            component="li"
                                                            sx={{
                                                                margin: 1,
                                                                opacity: 0.6,
                                                            }}
                                                        />
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={{
                                                                    color: 'text.primary',
                                                                    display:
                                                                        'inline',
                                                                }}
                                                            >
                                                                Voimassa olevat
                                                                lisenssit:
                                                            </Typography>
                                                            {userData?.licenses
                                                                ?.filter(
                                                                    l =>
                                                                        l.subscriber_guid ===
                                                                        school?.parent_guid
                                                                )
                                                                ?.map(
                                                                    (
                                                                        license,
                                                                        i
                                                                    ) => (
                                                                        <Stack
                                                                            key={`${userData.guid}-${license.guid}-${i}`}
                                                                            direction="row"
                                                                            alignItems="center"
                                                                            justifyContent="space-between"
                                                                            sx={{
                                                                                width: '100%',
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                component="span"
                                                                                variant="body2"
                                                                            >
                                                                                {
                                                                                    license.name
                                                                                }
                                                                            </Typography>
                                                                            <Box
                                                                                sx={{
                                                                                    flexGrow: 1,
                                                                                    height: '1px',
                                                                                    backgroundImage:
                                                                                        'linear-gradient(to right, transparent, black 50%, transparent)',
                                                                                    backgroundSize:
                                                                                        '4px 1px',
                                                                                    margin: '0 8px',
                                                                                }}
                                                                            />
                                                                            <Box
                                                                                sx={{
                                                                                    opacity: 1,
                                                                                    marginLeft:
                                                                                        'auto', // Float right
                                                                                }}
                                                                            >
                                                                                {new Date(
                                                                                    license.created
                                                                                ).toLocaleDateString(
                                                                                    'fi-FI'
                                                                                )}
                                                                            </Box>
                                                                        </Stack>
                                                                    )
                                                                )}
                                                        </React.Fragment>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))}
                    </List>
                </Grid>
            </Stack>
        </RecordContextProvider>
    );
};

const Merge = () => {
    const { record } = useEditContext();
    const resource = useResourceContext();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [similarUsers, setSimilarUsers] = useState(null);
    const [masterUser, setMasterUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [debouncedSearchQuery, setDebouncedSearchQuery] =
        useState(searchQuery);
    const [schools, setSchools] = useState(null);
    const [application, setApplication] = useState(null);
    const [sameSchools, setSameSchools] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();

    const refresh = useRefresh();
    const handleRefresh = () => {
        refresh();
    };

    const handleMerge = async user => {
        fetch(
            `${process.env.REACT_APP_INFRA_API}/user/${masterUser.guid}/merge`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify([
                    {
                        to: masterUser.guid,
                        from: user.guid,
                        move: {
                            groups: true,
                            licenses: true,
                            extramaterials: true,
                            favorites: true,
                            email: true,
                            edustore: true,
                            mpass: true,
                            tasks: false,
                        },
                        must_be_same_school: sameSchools,
                        preview: false,
                        newemail: '',
                        action_after: 'RELEASE_EXTRA_LICENSES',
                    },
                ]),
            }
        ).then(() => {
            notify(translate('merge.success'), {
                type: 'success',
            });
            handleRefresh();
            fetchSimilarUsers();
        });
    };

    const handleOpenDialog = user => {
        setSelectedUser(user);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedUser(null);
    };

    const handleContinue = () => {
        // Handle the continue action here
        handleMerge(selectedUser);
        handleCloseDialog();
    };

    const potentialConflict = slave => {
        let conflict = {
            hasConflict: false,
            reasons: [],
        };

        if (!masterUser || !slave) return conflict;

        // Check if both users have at least one same firstname. Check if two arrays have at least one common element.
        let masterFirstnames = masterUser.public.firstname
            ?.trim()
            ?.toLowerCase()
            ?.split(' ');
        let slaveFirstnames = slave.public.firstname
            ?.trim()
            ?.toLowerCase()
            ?.split(' ');
        if (!masterFirstnames.some(name => slaveFirstnames.includes(name))) {
            conflict.hasConflict = true;
            conflict.reasons.push('firstname');
        }

        // Check if both users have at least one same lastname. Check if two arrays have at least one common element.
        let masterLastnames = masterUser.public.lastname
            ?.trim()
            ?.toLowerCase()
            ?.split(' ');
        let slaveLastnames = slave.public.lastname
            ?.trim()
            ?.toLowerCase()
            ?.split(' ');
        if (!masterLastnames.some(name => slaveLastnames.includes(name))) {
            conflict.hasConflict = true;
            conflict.reasons.push('lastname');
        }

        // Check if both users have at least one same school. Check if two arrays have at least one common element.
        let masterSchools = masterUser.schools.map(s => s.guid);
        let slaveSchools = slave.schools.map(s => s.guid);
        if (!masterSchools.some(school => slaveSchools.includes(school))) {
            conflict.hasConflict = true;
            conflict.reasons.push('school');
        }

        // Check if both users have mpass service
        if (
            masterUser.services.includes('mpass') &&
            slave.services.includes('mpass')
        ) {
            conflict.hasConflict = true;
            conflict.reasons.push('mpass');
        }

        // Check if slave is login later than master
        if (
            new Date(slave.last_login || slave.registered) >
            new Date(masterUser.last_login || masterUser.registered)
        ) {
            conflict.hasConflict = true;
            conflict.reasons.push('last_login');
        }

        return conflict;
    };

    const fetchSimilarUsers = async () => {
        setIsSearching(true);
        if (!searchQuery || !application) {
            /*
            notify(translate('merge.nodata'), {
                type: 'warning',
            });
            */
            /*
            console.log(searchQuery);
            */
            setIsSearching(false);
            return;
        }

        //if (isSearching) return;

        try {
            const { data } = await dataProvider.getList('user', {
                filter: {
                    need_count: false,
                    status: '0,2',
                    application: application,
                    q: searchQuery,
                    schools: sameSchools ? schools : undefined,
                    licenses: true,
                    classes: true,
                    sessions: true,
                },
                pagination: { page: 1, perPage: 50 },
                sort: { field: 'id', order: 'ASC' },
            });
            if (data?.find(user => user.guid === record?.guid))
                setMasterUser(data?.find(user => user.guid === record?.guid));

            let prosessedUsers = data
                .filter(user => user.guid !== record?.guid)
                .map(user => {
                    user.potentialConflict = potentialConflict(user);
                    return user;
                });

            console.log(prosessedUsers);
            setSimilarUsers(prosessedUsers);
        } catch (error) {
            console.error(error);
        } finally {
            setIsSearching(false);
        }
    };

    useEffect(() => {
        if (!record) return;
        let query =
            record.public.firstname.split(' ')[0] +
            ' ' +
            record.public.lastname;
        let schools = record?.schools?.map(g => g.guid)?.join(',') || '';
        let application = record.application[0].guid;

        setSearchQuery(query);
        setSchools(schools);
        setApplication(application);

        setMasterUser(record);

        // Ensure fetchSimilarUsers is called on initial load
        fetchSimilarUsers();
    }, [record]);

    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 500);

        handler();

        return () => {
            handler.cancel();
        };
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedSearchQuery) {
            fetchSimilarUsers();
        }
    }, [debouncedSearchQuery, sameSchools]);

    if (!record) return null;

    return (
        <>
            {masterUser && masterUser.status > 0 && (
                <Alert severity="error" sx={{ padding: 10 }}>
                    {translate('merge.inactive')}
                </Alert>
            )}
            {masterUser && masterUser.status === 0 && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <Box boxShadow={3} p={2}>
                            <Box mb={2} boxShadow={1} p={2}>
                                <Typography variant="h6">
                                    {translate('merge.to.title')}
                                </Typography>
                                <UserDetails userData={masterUser} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box boxShadow={3} p={2}>
                            <>
                                {similarUsers?.length === 0 && (
                                    <Alert
                                        severity="warning"
                                        sx={{ padding: 10 }}
                                    >
                                        {translate('merge.nodata')}
                                    </Alert>
                                )}
                                {similarUsers?.map(user => (
                                    <Box
                                        key={user.id}
                                        mb={2}
                                        boxShadow={1}
                                        p={2}
                                    >
                                        <Typography variant="h6">
                                            {translate('merge.from.title')}
                                        </Typography>
                                        <UserDetails userData={user} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                backgroundColor: user
                                                    .potentialConflict
                                                    .hasConflict
                                                    ? 'red'
                                                    : 'green',
                                            }}
                                            onClick={() => {
                                                /*
                                        [
                                            {
                                                "to": "829efe43-f692-413e-a06f-813884133e5a",
                                                "from": "30bf1b59-65c0-4732-b00e-75f894a8e9ea",
                                                "move": {
                                                    "groups": true,
                                                    "licenses": true,
                                                    "extramaterials": true,
                                                    "favorites": true,
                                                    "email": true,
                                                    "edustore": true,
                                                    "mpass": true,
                                                    "tasks": false
                                                },
                                                "must_be_same_school": false,
                                                "preview": false,
                                                "newemail": "",
                                                "action_after": "RELEASE_EXTRA_LICENSES"
                                            }
                                        ]
                                    */
                                                if (
                                                    user.potentialConflict
                                                        .hasConflict
                                                ) {
                                                    handleOpenDialog(user);
                                                    return;
                                                }
                                                handleMerge(user);
                                            }}
                                        >
                                            {translate('merge.button')}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            href={`/#/${resource}/${user?.guid}/yhdistaminen`}
                                            sx={{ marginLeft: 1 }}
                                        >
                                            {translate('merge.change_profile')}
                                        </Button>
                                    </Box>
                                ))}
                                <Dialog
                                    open={open}
                                    onClose={handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {translate('merge.conflict.title')}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {translate(
                                                'merge.conflict.message'
                                            )}
                                            {/* Display the conflicts here */}
                                            {selectedUser?.potentialConflict.reasons.map(
                                                (reason, index) => (
                                                    <Typography
                                                        key={index}
                                                        variant="body2"
                                                        sx={{
                                                            color: 'error.main',
                                                            marginTop:
                                                                index === 0
                                                                    ? 1
                                                                    : 0,
                                                        }}
                                                    >
                                                        {translate(
                                                            `merge.conflict.${reason}`
                                                        )}
                                                    </Typography>
                                                )
                                            )}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleCloseDialog}
                                            color="primary"
                                            variant="outlined"
                                            autoFocus
                                        >
                                            {translate('merge.cancel')}
                                        </Button>
                                        <Button
                                            onClick={handleContinue}
                                            color="primary"
                                            variant="contained"
                                        >
                                            {translate('merge.continue')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box>
                            <Typography variant="h6">
                                {translate('merge.search.title')}
                                {isSearching && (
                                    <CircularProgress
                                        size={14}
                                        sx={{
                                            marginLeft: 2,
                                            position: 'relative',
                                            top: 2,
                                        }}
                                    />
                                )}
                            </Typography>
                            <Typography>
                                {translate('merge.search.description')}
                            </Typography>
                            <TextField
                                label={translate('merge.search.title')}
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                fullWidth
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sameSchools}
                                        onChange={e =>
                                            setSameSchools(e.target.checked)
                                        }
                                    />
                                }
                                label={translate(
                                    'merge.search.must_be_same_school'
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default Merge;
