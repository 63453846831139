import * as React from 'react';
import {
    Datagrid,
    Edit,
    NumberInput,
    BooleanInput,
    ReferenceManyField,
    TextInput,
    FormTab,
    SaveButton,
    Toolbar,
    FileInput,
    FileField,
    DateTimeInput,
    TabbedForm,
    ReferenceField,
    FunctionField,
    DateField,
    DateInput,
    Labeled,
    useTranslate,
    BooleanField,
    useEditContext,
    TextField,
    useRecordContext,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
} from 'react-admin';
import { Grid, Box, Typography, Stack } from '@mui/material';

import {
    CustomSelectInput,
    CustomSelectArrayInput,
    CustomCheckboxGroupInput,
    CustomRadioButtonGroupInput,
    CustomJSONPretty,
    CustomTextField,
    CustomBulkDeleteButton,
    CustomBulkAddButton,
    CustomProductList,
    CustomNumberInput,
    CustomLogEventList,
    CustomUserLicenseByUserList,
    CustomUserSchoolList,
    CustomUserGroupList,
} from '../../controls';

import Merge from '../controls/Merge';

import {
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    TransferWithinAStation,
    History,
    Computer,
} from '@mui/icons-material';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import CustomStatistics from '../../controls/CustomStatistics';
import StatsWithIcon from '../../../components/statistics/controls/StatsWithIcon';
import SourceNameField from '../SourceNameField';
import SessionBadge from '../controls/SessionBadge';

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const ApplicationForm = () => {
    const useEdit = useEditContext();
    //console.log('record', record);
    return (
        <RowForm defaultValues={{ user_guid: useEdit.record.guid }}>
            <CustomSelectInput
                resource="userapplication"
                source="application_guid"
                url="extralist/userapplication/applications"
                label=""
                validate={[required()]}
            />
            <BooleanInput
                resource="userapplication"
                source="isadmin"
                label=""
            />
            <CustomSelectInput
                resource="userapplication"
                source="public.userrights"
                url="extralist/userapplication/userrights"
                label=""
                showIfControl="application_guid"
                showIfValue="13b312b5-367c-4c74-bb77-bdb34df4e721"
                validate={[required()]}
            />
            <CustomSelectInput
                resource="userapplication"
                source="status"
                url="extralist/userapplication/statuses"
                label=""
                validate={[required()]}
            />
        </RowForm>
    );
};

const SchoolFormEdit = () => {
    const useEdit = useEditContext();
    //console.log('record', record);
    return (
        <RowForm
            defaultValues={{
                user_guid: useEdit.record.guid,
                group_guid: useEdit.record.group_guid,
            }}
        >
            {/* <CustomAutocompleteInput resource="usergroup" source="group_guid" url="extralist/schools" /> */}
            <TextField resource="usergroup" source="group.name" label="" />
            <DateField resource="usergroup" source="author.created" label="" />
            <CustomSelectInput
                resource="usergroup"
                source="public.materialcharge"
                label=""
                url="extralist/student/materialcharges"
            />
            <CustomSelectInput
                resource="usergroup"
                source="status"
                label=""
                url="extralist/student/statuses"
            />
        </RowForm>
    );
};

const ClassList = () => {
    const useRecord = useRecordContext();
    //console.log('useEditSchool', useRecord);
    return (
        <Edit
            resource="group"
            id={useRecord.group_guid}
            title=" "
            className="cgl-dg-edit"
        >
            <Grid container width={{ xs: '100%' }} spacing={2}>
                <Grid item xs={12} md={12}>
                    <CustomUserGroupList
                        disableSyncWithLocation
                        storeKey="customUserGroupList"
                        resource="usergroup"
                        params={{ user_guid: useRecord.user_guid }}
                        filter={{ type: 4 }}
                        customFilters={['parent_guid', 'user_guid']}
                        showExtra={['']}
                        hideFields={['']}
                        bulkActionButtons={false}
                    >
                        <span />
                    </CustomUserGroupList>
                </Grid>
            </Grid>
        </Edit>
    );
};

const StudentEdit = () => {
    const translate = useTranslate();

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab
                    label="resources.student.tabs.profile"
                    icon={<Article />}
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextInput
                                resource="student"
                                source="public.firstname"
                                validate={[required()]}
                                fullWidth
                            />
                            <TextInput
                                resource="student"
                                source="public.lastname"
                                validate={[required()]}
                                fullWidth
                            />
                            <TextInput
                                resource="student"
                                source="public.email"
                                fullWidth
                            />
                            <TextInput
                                resource="student"
                                source="public.emails"
                                fullWidth
                                disabled
                            />
                            <TextInput
                                resource="student"
                                source="username"
                                fullWidth
                                disabled
                            />
                            <CustomSelectInput
                                resource="student"
                                source="status"
                                url="extralist/student/statuses"
                                parse={v => parseInt(v)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Typography variant="subtitle1">
                                {translate(
                                    'resources.student.fields.group.valid'
                                )}
                            </Typography>
                            <Typography variant="body2">
                                {translate(
                                    'resources.student.fields.group.valid_desc'
                                )}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <DateInput
                                    resource="student"
                                    source="public.validfrom"
                                />
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <DateInput
                                    resource="student"
                                    source="public.validto"
                                />
                            </Box>

                            <BooleanInput
                                resource="student"
                                source="public.login_not_allowed"
                            />
                            <BooleanInput
                                resource="student"
                                source="public.profile_locked"
                            />

                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: 2 }}
                            >
                                <Labeled>
                                    <SessionBadge
                                        source="last_login"
                                        options={{
                                            showTTL: true,
                                            showSessionDelete: true,
                                        }}
                                    >
                                        <DateField
                                            resource="teacher"
                                            source="last_login"
                                            showTime
                                            locales={'fi'}
                                        />
                                    </SessionBadge>
                                </Labeled>
                            </Box>
                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: 2 }}
                            >
                                <Labeled label="resources.student.fields.services">
                                    <SourceNameField showRemove={true} />
                                </Labeled>
                            </Box>

                            <Stack direction="row" spacing={4}>
                                <Box>
                                    <Labeled label="resources.student.fields.author.creator">
                                        <>
                                            <ReferenceField
                                                reference="user"
                                                source="author.creator"
                                            >
                                                <FunctionField
                                                    render={record =>
                                                        `${record.public.firstname} ${record.public.lastname}`
                                                    }
                                                />
                                            </ReferenceField>
                                            <DateField
                                                resource="student"
                                                source="registered"
                                                showTime
                                                locales={'fi'}
                                            />
                                        </>
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled label="resources.student.fields.author.modifier">
                                        <>
                                            <ReferenceField
                                                reference="user"
                                                source="author.modifier"
                                            >
                                                <FunctionField
                                                    render={record =>
                                                        `${record.public.firstname} ${record.public.lastname}`
                                                    }
                                                />
                                            </ReferenceField>
                                            <DateField
                                                resource="student"
                                                source="author.modified"
                                                showTime
                                                locales={'fi'}
                                            />
                                        </>
                                    </Labeled>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.user.tabs.application"
                    icon={<Computer />}
                    path="palvelut"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <ReferenceManyField
                                reference="userapplication"
                                target="user_guid"
                            >
                                <EditableDatagrid
                                    createForm={<ApplicationForm />}
                                    editForm={<ApplicationForm />}
                                >
                                    <CustomSelectInput
                                        resource="userapplication"
                                        source="application_guid"
                                        label={translate(
                                            'resources.user.fields.application.name'
                                        )}
                                        url="extralist/userapplication/applications"
                                        showAsText={true}
                                    />
                                    <BooleanField
                                        resource="userapplication"
                                        source="isadmin"
                                        label={translate(
                                            'resources.user.fields.application.isadmin'
                                        )}
                                    />
                                    <CustomSelectInput
                                        resource="userapplication"
                                        source="public.userrights"
                                        label={translate(
                                            'resources.user.fields.application.role'
                                        )}
                                        url="extralist/userapplication/userrights"
                                        showAsText={true}
                                    />
                                    <CustomSelectInput
                                        resource="userapplication"
                                        source="status"
                                        label={translate(
                                            'resources.user.fields.application.status'
                                        )}
                                        url="extralist/userapplication/statuses"
                                        showAsText={true}
                                    />
                                </EditableDatagrid>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.student.tabs.schools_and_groups"
                    icon={<LibraryBooks />}
                    path="koulut_ja_ryhmat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <ReferenceManyField
                                reference="usergroup"
                                target="user_guid"
                                filter={{ type: 2 }}
                            >
                                <EditableDatagrid
                                    bulkActionButtons={false}
                                    editForm={<SchoolFormEdit />}
                                    expand={<ClassList />}
                                    expandSingle={true}
                                    rowClick="expand"
                                    noDelete
                                >
                                    {/* <CustomSelectInput resource="usergroup" source="group_guid" label={translate('resources.user.fields.group.name')} url="extralist/schools" filter="group_guid" showAsText={true} /> */}
                                    <TextField
                                        resource="usergroup"
                                        source="group.name"
                                        label={translate(
                                            'resources.user.fields.group.name'
                                        )}
                                    />
                                    <DateField
                                        resource="usergroup"
                                        source="author.created"
                                        locales={'fi'}
                                        label={translate(
                                            'resources.student.fields.school.registered'
                                        )}
                                    />
                                    <FunctionField
                                        resource="usergroup"
                                        label={translate(
                                            'resources.student.fields.materialcharge.title'
                                        )}
                                        render={function (
                                            record: any
                                        ): React.ReactNode {
                                            return record?.public
                                                ?.materialcharge === '1'
                                                ? translate(
                                                      'resources.student.fields.materialcharge.yes'
                                                  )
                                                : record?.public
                                                      ?.materialcharge === '0'
                                                ? translate(
                                                      'resources.student.fields.materialcharge.no'
                                                  )
                                                : translate(
                                                      'resources.student.fields.materialcharge.unknown'
                                                  );
                                        }}
                                    />
                                    <CustomSelectInput
                                        resource="usergroup"
                                        source="status"
                                        label={translate(
                                            'resources.user.fields.group.status'
                                        )}
                                        url="extralist/teacher/statuses"
                                        showAsText={true}
                                    />

                                    {/* <CustomSelectInput resource="usergroup" source="public.userrights" label={translate('resources.user.fields.group.role')} url="extralist/userapplication/userrights" showAsText={true} />
                                     */}
                                </EditableDatagrid>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.student.tabs.materials"
                    icon={<LibraryBooks />}
                    path="kaytossa"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserLicenseByUserList
                                resource="userlicense"
                                disableSyncWithLocation
                                customFilters={['user_guid']}
                                showExtra={[]}
                                hideFields={[]}
                                sort={{ field: 'product.name', order: 'ASC' }}
                                bulkActionButtons={<CustomBulkDeleteButton />}
                            >
                                <span />
                            </CustomUserLicenseByUserList>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.student.tabs.merge"
                    icon={<LibraryBooks />}
                    path="yhdistaminen"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Merge />
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.teacher.tabs.logs"
                    icon={<History />}
                    path="logit"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomStatistics
                                statisticKey="userEvents"
                                handler={StatsWithIcon}
                                mode="List"
                                from="30d"
                                limit={30}
                                exclude_search={['FORCE_LOGOUT']}
                                include_search={[]}
                                pagination={true}
                                showFilter={true}
                            />
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
};

export default StudentEdit;
